<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <span>URLS</span>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            outlined
            @click="modal = true, clearModal()"
          >
            <v-icon class="mr-1">
              {{ icons.mdiPlaylistPlus }}
            </v-icon>
            Novo
          </v-btn>
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
          >
            <v-data-table
              :loading="loadTable"
              loading-text="Carregando dados... Por favor, aguarde"
              :headers="headers"
              :items="items"
              :items-per-page="10"
            >
              <template #[`item.edit`]="{ item }">
                <v-icon
                  color="info"
                  @click="getUrlInfo(item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <template #[`item.delete`]="{ item }">
                <v-icon
                  color="error"
                  @click="deleteUser(item)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
          v-model="modal"
          width="600"
        >
          <v-card>
            <v-card-title
              color="error"
            >
              <h3>URL</h3>
            </v-card-title>
            <v-card>
              <v-overlay
                v-if="loadModal"
              >
                <v-progress-circular
                  indeterminate
                />
              </v-overlay>
              <v-card-text>
                <form action="">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="url.application_name"
                        dense
                        outlined
                        label="Nome da aplicação"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="url.url"
                        dense
                        outlined
                        label="URL"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="url.group"
                        dense
                        outlined
                        label="Grupo"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-select
                        v-model="url.systems_id"
                        :items="systems"
                        dense
                        outlined
                        label="Sistema"
                        variant="underlined"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col
                      v-if="edit"
                      cols="auto"
                    >
                      <v-btn
                        color="success"
                        prepend-icon="mdi-content-save"
                        @click="saveChanges"
                      >
                        salvar
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else
                      cols="auto"
                    >
                      <v-btn
                        color="info"
                        prepend-icon="mdi-plus"
                        @click="createURL"
                      >
                        cadastrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
            </v-card>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axiosIns from '@/plugins/axios'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      loadModal: false,
      modal: false,
      edit: false,
      urlId: '',
      loadTable: false,
      headers: [
        { text: 'APLICAÇÃO', value: 'application_name' },
        { text: 'URL', value: 'url' },
        { text: 'GRUPO', value: 'group' },
        { text: 'EDITAR', value: 'edit', align: 'center' },
        { text: 'DELETAR', value: 'delete', align: 'center' },
      ],
      items: [],
      infoBase: {},
      url: {},
      systems: [],
    }
  },
  created() {
    this.getURLs()
    this.getSystems()
  },
  methods: {
    clearModal() {
      this.edit = false
      this.url = {
        application_name: '',
        url: '',
        group: '',
        systems_id: '',
      }
    },

    closeModal() {
      this.modal = false
      this.getURLs()
    },

    async saveChanges() {
      await axiosIns
        .put(`/api/v1/records/url/update/${this.urlId}`, this.url)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'URL atualizado!',
            text: 'Dados ds URL atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados da URL!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    async createURL() {
      await axiosIns
        .post('/api/v1/records/url/store', this.url)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'URL cadastrado!',
            text: 'Nova URL cadastrado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar URL!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    async getUrlInfo(item) {
      this.loadModal = true
      this.modal = true
      this.urlId = item.id
      this.edit = true
      await axiosIns.get(`/api/v1/records/url/edit/${item.id}`).then(res => {
        this.infoBase = res.data.data
        this.url = res.data.data
        this.loadModal = false
      })
    },

    async deleteUser(item) {
      await this.$swal({
        icon: 'warning',
        title: 'Deseja excluir a URL?',
        text: 'A operação é irreversível, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
      })
        .then(async result => {
          if (result.isConfirmed) {
            await axiosIns.delete(`/api/v1/records/url/destroy/${item.id}`).then(async () => {
              this.closeModal()
              await this.$swal({
                icon: 'success',
                title: 'URL excluída!',
                text: 'A URL foi excluída com sucesso!',
                showConfirmButton: false,
                timer: 2000,
              })
            })
          } else {
            await this.$swal({
              icon: 'error',
              title: 'Cancelado!',
              text: 'Operação cancelada',
              showConfirmButton: false,
              timer: 2000,
            })
          }
        })
        .catch(async error => {
          this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao excluir URL!',
            text: error,
          })
        })
    },

    async getURLs() {
      this.loadTable = true
      await axiosIns.get('/api/v1/records/url/index').then(res => {
        this.items = res.data.data
      })
      this.loadTable = false
    },

    async getSystems() {
      await axiosIns.get('/api/v1/records/system/index').then(res => {
        res.data.data.map(key => {
          this.systems.unshift({
            text: key.system,
            value: key.id,
          })
        })
      })
    },
  },
}
</script>

<style>
</style>
